import { Link } from "react-router-dom";
import PlayImg from "../assets/images/play.png";
import Elipses from "../assets/images/elipses.png";
import BG from "../assets/images/bg.png";


export const Approach = () => {
    return (
        <>
            <div className="hidden bg-[#FDFAF3] lg:py-20 min-h-[400px] md:min-h-[90svh] flex items-center justify-center" id="approach" style={{ backgroundColor: 'rgba(0, 0, 0, 0.70)' }}>
                <div className="mx-auto h-full overflow-hidden mx-auto w-full max-w-screen-xl  p-4 py-10  text-[#292D32] h-full flex items-center justify-center">

                    <div className="l">
                        <h2 className="text-2xl md:text-3xl font-semibold text-[#fff] text-center mb-3">
                            #Our Story
                        </h2>


                        <img src={PlayImg} alt="play" className="w-[40%] mx-auto" />

                    </div>
                    <div>

                    </div>
                </div>
            </div>


            <div id="choose" className="hidden mx-auto h-full overflow-hidden mx-auto w-full max-w-screen-xl  p-4 py-10  text-[#292D32] h-full relative">

                <div className="absolute left-0 w-full mt-4">
                    <img src={BG} alt="play" className="w-[30%] mx-auto" />
                </div>

                <div className="text-center mt-5 md:mt-20 pt-14">
                    <div className="w-fit mx-auto bg-[#DCDCDC33] text-md font-[600] p-3 px-5 rounded-2xl">
                        Why Choose Us
                    </div>
                    <h5 className="text-md lg:text-xl font-semibold mt-5"> Reasons Why You Should Choose Angel Of Royalty Home Care</h5>
                </div>

            </div>

            <div className="mx-auto hidden w-full max-w-screen-xl p-4 md:pb-10 flex  text-[#292D32] h-full grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-10">
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Onsite House Manager</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our house manager stays in the home to ensure rules are observed.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Re-Creation Center</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our recreation center is well furnished and equipped recreation room to keep you busy.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Fully Furnished</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Angel Of Royalty Home Care is 100% furnished with the modern interior to suite everyone</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">24/7 Surveillance</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our Home Care is under 24/7 surveillance making our home safe and secure.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Wi-Fi Services</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our Care home  has 24/7 wi-fi services  installed for you to browse the internet.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Weekly Group Events</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our weekly group events consists of movie nights, picnics, arts and crafts and pizza party.</p>
                </div>


            </div>

            <div id="consulting" className="mx-auto w-full max-w-screen-xl p-5 md:py-10  bg-[#F8F8F8] rounded-2xl   text-[#292D32] h-full mb-10 ">
                <h5 className="text-md lg:text-2xl font-semibold mt-5 text-center w-full">Looking For a clean and Comfortable Place to Call Home??</h5>
                <p className="my-6 mt-2 text-md lg:w-[100%] text-[#292D32] text-center lg:text-center font-[500]">
                    At Wonderful life care home, We provide you with the absolute best home care services
                </p>

                <form className="mt-10 w-full md:w-1/3 mx-auto text-center">

                <a className="mt-1 bg-[#640086] rounded-[16px] px-8 py-3.5 text-white text-sm font-[500] w-fit mx-auto block md:inline" href="tel:+832-745-7396">Contact Us</a>

                    {/* <button
                        className="mt-1 w-full rounded-2xl bg-[#640086] px-6 py-3.5 text-sm font-bold tracking-wide text-white transition-none hover:bg-teal-600 sm:mt-0 sm:w-auto sm:shrink-0"
                    >
                        Contact Us
                    </button> */}
                    {/* <label htmlFor="UserEmail" className="sr-only"> Email </label>

                    <div
                        className="rounded-md  p-2 my-10 focus-within:transparent sm:flex sm:items-center sm:gap-4 bg-white placeholder:text-[#262626]"
                    >
                        <input
                            type="email"
                            id="UserEmail"
                            placeholder="Enter Email Address"
                            className="w-full border-none bg-transparent focus:border-transparent focus:ring-transparent sm:text-sm text-sm h-full  py-3 outline-none border-none block"
                        />

                        <button
                            className="mt-1 w-full rounded-2xl bg-[#B931F9] px-6 py-3.5 text-sm font-bold tracking-wide text-white transition-none hover:bg-teal-600 sm:mt-0 sm:w-auto sm:shrink-0"
                        >
                            Subscribe
                        </button>
                    </div> */}
                </form>
            </div>
        </>
    )
}