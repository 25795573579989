import { Link } from "react-router-dom";
import HeroImg from "../assets/images/hero-3.png";
import WheelChair from "../assets/images/wheelchair.svg";
import Nurse from "../assets/images/nurse.svg";
import NurseTwo from "../assets/images/nurse-2.svg";
import NurseThree from "../assets/images/nurse-3.svg";
import users from "../assets/images/users.svg";

export const Hero = () => {
    return (
        <nav className="bg-[#F3F3F3]  relative top-[50px] pt-12 pb-5 lg:pt-0 md:top-[80px] lg:top-[40px] lg:pb-10 lg:pt-32" id="home">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-3 flex flex-col lg:flex-col items-center gap-4 md:gap-10 justify-center flex-shrink-0 text-[#292D32] h-full">
                <div className="lg:w-[1/2]">
                    <h2 className="text-4xl md:text-5xl font-[700] text-[#262626] text-center lg:text-center mb-3">
                        Welcome, Senior Living With

                    </h2>
                    <h2 className="text-4xl md:text-5xl font-[700] text-[#262626] text-center lg:text-center">
                        Dignity And Respect

                    </h2>

                    <p className="my-10 text-md lg:w-[80%] mx-auto text-[#292D32] text-center lg:text-center font-[500]">
                        Experience the highest Level of care and comfort for your loved ones.
                    </p>

                    <div className="my-8 flex items-center justify-center lg:justify-center gap-">

                        <a className="bg-[#640086] rounded-[16px] px-8 py-3.5 text-white text-sm font-[500]" href="#services">Explore Our Services</a>

                    </div>
                </div>

                <div className="w-full">


                    <div className="grid grid-cols-2 gap-4 items-end md:hidden">
                        <div>
                            <img src={WheelChair} alt="Hero" className=" md:hidden lg:w-[100%] ml-auto" />
                            <div className="text-xs font-[400] mt-3 bg-[#00BFFE] p-3 rounded-2xl">Semi-private, private, fully furnished semi-private rooms, Transportation for medical visits</div>
                        </div>
                        <div>
                            <div className="text-xs font-[400] mb-5 bg-[#9B9BFF] p-3 rounded-2xl">Round the clock monitoring and medication management</div>
                            <img src={Nurse} alt="Hero" className=" md:hidden lg:w-[100%] ml-auto" />

                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 items-end mt-4 md:hidden">
                        <div>
                            <img src={NurseTwo} alt="Hero" className=" md:hidden lg:w-[100%] ml-auto" />
                            <div className="text-xs font-[400] mt-3 bg-[#EBBEFB] p-3 rounded-2xl">Physical wellness, physical therapy and pet therapy</div>
                        </div>
                        <div>
                            <div className="text-xs font-[400] mb-5 bg-[#F5F5DC] p-3 rounded-2xl">Respite, hospice and palliative care, nutrition and dining</div>
                            <img src={NurseThree} alt="Hero" className=" md:hidden lg:w-[100%] ml-auto" />

                        </div>
                    </div>
                    <img src={HeroImg} alt="Hero" className="hidden md:block lg:w-[100%] ml-auto" />
                </div>

                <div>

                </div>
            </div>
        </nav>
    )
}