import Logo from "../assets/life-logo.svg";
import Call from "../assets/images/call-outgoing.svg";
import Location from "../assets/images/location.svg";
import Mail from "../assets/images/mark_email_unread.svg";


export const Footer = () => {
    return (

        <footer className="py-8 md:py-0 bg-[#F8F8F8]">
            <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
                <div className="lg:grid lg:grid-cols-3">




                    <div className="py-0 lg:py-16 lg:pe-16">
                        <div className="text-teal-600 lg:block">
                            <div className="block text-teal-600 w-24">
                                <img src={Logo} alt="Footer" className="w-[50px] md:w-[100px]" />
                            </div>
                        </div>

                        <div className="mt-8 w-full">
                            <div>
                                <p className="font-medium text-gray-900 text-sm">We provide a wide variety of special needs populations with all-inclusive affordable housing. We are committed to providing our clients with clean, safe all inclusive living arrangements that cannot be found elsewhere.</p>


                            </div>
                        </div>
                    </div>



                    <div className="py-0 lg:py-16 lg:pe-16">

                        <div className="mt-8 md:w-fit mx-auto">


                            <div>
                                <p className="font-medium text-[#262626] font-semibold">Company</p>

                                <ul className="mt-3 space-y-3 text-sm">
                                    <li>
                                        <a href="#about" className="text-sm font-[500] text-[#262626]">
                                            About
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#services" className="text-sm font-[500] text-[#262626]">
                                            Our Services
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#approach" className="text-sm font-[500] text-[#262626]">
                                            Our Story
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#choose" className="text-sm font-[500] text-[#262626]">
                                            Mission & Vision
                                        </a>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="py-0 lg:py-16 lg:pe-16" id="contact">

                        <div className="mt-8 md:w-fit mx-auto">


                            <div>
                                <p className="font-medium text-[#262626] font-semibold">Contact Us</p>

                                <ul className="mt-3 space-y-3 text-sm">
                                    <li className="flex items-center gap-2">
                                        <img src={Call} alt="call" />


                                        <a href="tel:+832-745-7396" className="text-sm font-[500] text-[#262626]">
                                            832-745-7396
                                        </a>
                                        <a href="tel:+(832)229-7277" className="text-sm font-[500] text-[#262626]">
                                            (832) 229-7277
                                        </a>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <img src={Location} alt="call" />


                                        <a href="#0" className="text-sm font-[500] text-[#262626]">
                                            Houston, Spring,  Katy & Conroe
                                        </a>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <img src={Location} alt="call" />


                                        <a href="#0" className="text-sm font-[500] text-[#262626]">
                                        Kingwood, Humble.
                                        </a>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <img src={Mail} alt="call" />


                                        <a href="mailto:rochelle@wonderfullifecarehomes.com" className="text-sm font-[500] text-[#262626]">
                                            rochelle@wonderfullifecarehomes.com
                                        </a>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>)
}