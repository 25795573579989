import { Link } from "react-router-dom";
import AboutImg from "../assets/images/about-img.png";
import Elipses from "../assets/images/star.svg";
import Mission from "../assets/images/man.png";
import Union from "../assets/images/Union.png";

export const About = () => {
    return (
        <>
            <div className="bg-[#fff]  py-10" id="about">
                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex flex-col-reverse  md:flex-col lg:flex-row items-center justify-between flex-shrink-0 text-[#292D32] h-full">
                    <div className="lg:w-1/2">
                        <div className="">
                            <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left mb-3">
                                About Wonderful Life

                            </h2>
                            <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left">
                                Care Home
                            </h2>

                        </div>

                        <p className="md:my-8 text-md lg:w-[80%] text-[#292D32] text-center font-[500] lg:text-left">
                            Wonderful Life Care home specializes in providing seniors affordable, versatile housing options. We are devoted to ensuring our clients reside in environments that are not only clean and safe but also inclusive, offering a superior living experience that truly sets us apart.
                        </p>
                        <div className="mt-5 md:mt-0 flex items-center justify-center lg:justify-start gap-5">
                            <a className="bg-[#640086] rounded-[16px] px-8 py-3.5 text-white text-sm font-[500]" href="tel:+832-745-7396">Contact US</a>

                        </div>

                    </div>

                    <div className="w-full md:w-1/2  relative">

                        <div className="md:py-5">

                            <img src={AboutImg} alt="Hero" className="lg:w-[70%] relative my-10 md:my-0  ml-auto" />
                        </div>
                    </div>

                    <div>

                    </div>
                </div>
            </div>
            <div className="bg-[#F3F3F3]">

                <div id="services" className="bg-[#F3F3F3] mx-auto w-full max-w-screen-xl p-4 md:py-5 flex flex-col lg:flex-row items-center justify-center flex-shrink-0 text-[#262626] h-full">
                    <div className="w-full py-5 md:py-10  md:py-0 lg:w-1/2">

                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-center mb-1">
                            Services We Offer

                        </h2>

                    </div>
                </div>

                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 md:pt-0 flex text-[#292D32] h-full grid grid-cols-1 md:grid-cols-3 gap-10">
                    <div>
                        <img src={Elipses} alt="elipses" className="w-[6%]" />
                        <h5 className="text-md font-semibold mt-2">Semi-private/private and fully furnished semi-private</h5>
                        <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our rooms provide a comfortable and homelike environment with all the necessary amenities.</p>
                    </div>
                    <div>
                        <img src={Elipses} alt="elipses" className="w-[6%]" />
                        <h5 className="text-md font-semibold mt-2">Physical wellness /Physical Therapy</h5>
                        <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Gentle exercise programs like yoga and tai chi, tailored to accommodate all levels of mobility with rehabilitation and to maintain or improve physical strength and flexibility.</p>
                    </div>
                    <div>
                        <img src={Elipses} alt="elipses" className="w-[6%]" />
                        <h5 className="text-md font-semibold mt-2">Pet Therapy</h5>
                        <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Incorporates animals to improve mental and physical health, offering comfort and joy to residents.</p>
                    </div>
                    <div>
                        <img src={Elipses} alt="elipses" className="w-[6%]" />
                        <h5 className="text-md font-semibold mt-2">Respite Care</h5>
                        <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our house manager stays in the home to endure rules are observed.</p>
                    </div>
                    <div>
                        <img src={Elipses} alt="elipses" className="w-[6%]" />
                        <h5 className="text-md font-semibold mt-2">Hospice and Palliative Care</h5>
                        <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">We partner with specialized services for end of life care, focusing on relieving symptoms stress</p>
                    </div>
                    <div>
                        <img src={Elipses} alt="elipses" className="w-[6%]" />
                        <h5 className="text-md font-semibold mt-2">Transportation And Medical Visits</h5>
                        <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">We arrange trips to and from healthcare appointments, ensuring access to necessary medical care.</p>
                    </div>
                    <div>
                        <img src={Elipses} alt="elipses" className="w-[6%]" />
                        <h5 className="text-md font-semibold mt-2">Medical Management</h5>
                        <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Ensures that medications are taken correctly and on time, under the supervision of our staff.</p>
                    </div>
                    <div>
                        <img src={Elipses} alt="elipses" className="w-[6%]" />
                        <h5 className="text-md font-semibold mt-2">Nutrition And Dining</h5>
                        <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Offer three balanced, nutritious meals with snacks daily, catered to dietary needs and preferences.</p>
                    </div>
                    <div>
                        <img src={Elipses} alt="elipses" className="w-[6%]" />
                        <h5 className="text-md font-semibold mt-2">Round The Clock Monitoring</h5>
                        <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">House managers are available 24/7 to ensure safety and provide assistance whenever needed.</p>
                    </div>


                </div>
            </div>

            <section className="bg-[#fff]">
                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex  text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
                    <div className="py-5 md:py-10">
                        <img src={Mission} alt="elipses" className="md:w-[80%] h-full object-fit-cover rounded-2xl" />

                    </div>

                    <div id="mission">
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left mb-1">
                            Smart Devices For

                        </h2>
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left">
                            Video Chat

                        </h2>

                        <div className="md:w-[70%]">
                            <div className="bg-white py-4 rounded-xl">
                                <p className="my-2 text-sm lg:w-1080%] text-[#292D32] text-center md:text-left  font-[500]">At wonderful life care home, we embrace innovative technology to bridge the gap between our senior residents and their loved ones. Our facility is equipped with cutting-edge smart devices that facilitate effortless video chatting, enabling our seniors to stay connected with family members no matter the distance.</p>
                            </div>
                        </div>
                    </div>




                </div>
                <div  className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex  text-[#292D32] h-full grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
                    <div>
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center lg:text-left mb-1">
                            Mission & Vision
                        </h2>

                        <div className="md:w-[70%]">
                            <div className="bg-white py-4 rounded-xl mb-4">
                                <p className="my-2 text-sm lg:w-1080%] text-[#292D32] text-center md:text-left  font-[500]">At wonderful life care homes, our philosophy is centered around creating a better tomorrow through exceptional care and unwavering services quality within our residential setting. Our ethics is to give our clients a home away from home, an abode of warmth, familiarity, and comfort. We see every member as family, fostering a positive and uplifting atmosphere for all. Our comprehensive array of services. including three nutritious meals with snacks daily, round-the-clock monitoring by trained staff, holistic therapy, medication management, and transportation for medical visits, ensures that every aspect of our residents' needs are net with meticulous attention.</p>
                            </div>

                            <a className="bg-[#640086] rounded-[16px] px-8 py-3.5 text-white text-sm font-[500] w-fit mx-auto block md:inline" href="tel:+832-745-7396">Contact Us</a>

                        </div>
                    </div>

                    <div className="py-5 md:py-10">
                        <img src={Union} alt="elipses" className="md:w-[80%] h-full object-fit-cover rounded-2xl" />

                    </div>



                </div>
            </section>
        </>
    )
}