import logo from './logo.svg';
import './App.css';
import { Header } from "./components/Header";
import { Hero } from './components/Hero';
import { About } from './components/About';
import { Mission } from './components/Mission';
import { Footer } from './components/Footer';
import { Info } from './components/Info';
import { Testimonials } from './components/Testimonials';
import { Approach } from './components/Approach';
import Programs from './components/Programs';

function App() {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Programs />
      {/* <Mission /> */}
      <Approach />
      {/* <Testimonials /> */}
      {/* <Info /> */}
      <Footer />
    </>
  );
}

export default App;
